
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[path0]/[path1]/l/[path3]",
      function () {
        return require("private-next-pages/[path0]/[path1]/l/[path3]/index.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[path0]/[path1]/l/[path3]"])
      });
    }
  